import React, { useState, useEffect } from 'react'
import './Hero.css'
import axios from 'axios';
import {
  MDBAnimation,
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTypography,
} from "mdb-react-ui-kit";


const Hero = () => {


  // Get the selected faculty from local storage
  const selectedFaculty = localStorage.getItem("selectedFaculty");

  // Fetch all faculty data from databsae

  const [data, setData] = useState(null);
  const [email, setEmail] = useState("");
  const [latestNews, setLatestNews] = useState("");
  const [phone, setPhone] = useState("");
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  useEffect(() => {
    const facultyName = getFacultyNameFromPath();

    if (facultyName) {
      // Fetch data
      axios
        .get(`/api/get_data.php?facultyName=${facultyName}`)
        .then((response) => {
          setData(response.data.data);
        })
        .catch((error) => {});

      // Fetch email
      axios
        .get(`/api/get_email.php?facultyName=${facultyName}`)
        .then((response) => {
          setEmail(response.data.email);
        })
        .catch((error) => {});

      // Fetch latestNews
      axios
        .get(`/api/get_latestnews.php?facultyName=${facultyName}`)
        .then((response) => {
          setLatestNews(response.data.inputValue); // Use response.data.inputValue
        })
        .catch((error) => {});

      // Fetch phone
      axios
        .get(`/api/get_phone.php?facultyName=${facultyName}`)
        .then((response) => {})
        .catch((error) => {});
    }
  }, []);

  const getFacultyNameFromPath = () => {
    const path = window.location.pathname;
    const pathParts = path.split("/");
    return pathParts.length > 1 ? pathParts[pathParts.length - 1] : null;
  };

  // END OF = Fetch all faculty data from database

  const fetchUserData = async () => {
    // Retrieve the firebaseUserId from localStorage
    const firebaseUserId = localStorage.getItem("firebaseUserId");

    if (!firebaseUserId) {
      setError("No Firebase User ID found.");
      return;
    }

    try {
      const response = await axios.post("/api/get_user_details.php", {
        firebaseUserId,
      });
      const userData = response.data;

      // Check if the response was successful and user data is available
      if (userData && userData.id) {

        // Update the states
        setUsername(userData.username);
        setFirstName(userData.FirstName);
        setLastName(userData.LastName);
      } else {
        setError("Failed to fetch user data or user data is incomplete.");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError("Failed to fetch user data.");
    }
  };

  // Call fetchUserData function on component mount
  useEffect(() => {
    fetchUserData();
  }, []); // Ensure the dependency array is empty to avoid fetching on every render



  // CHANGELOG

  const [basicModal, setBasicModal] = useState(false);
  const toggleOpen = () => setBasicModal(!basicModal);
  const [changelogs, setChangelogs] = useState([]);

  // Function to load and set changelogs from the API
  const loadChangelogs = async () => {
    try {
      const response = await axios.get("/api/get_changelog.php");
      const fetchedChangelogs = response.data.map((changelog) => ({
        ...changelog,
        formattedDate: formatDate(changelog.date), // Format the date
      }));

      // Reverse the order of fetchedChangelogs
      const reversedChangelogs = fetchedChangelogs.reverse();

      setChangelogs(reversedChangelogs);
    } catch (error) {
      console.error("Error fetching changelogs:", error);
    }
  };

  // Format the date from "YYYY-MM-DD HH:MM:SS" to "DD/MM/YYYY"
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Load changelogs when the component mounts
  useEffect(() => {
    loadChangelogs();
  }, []);

  // CHANGELOG END

  return (
    <MDBContainer fluid className="mb-5 position-relative p-5">
      <div
        className="text-center rounded bg-image"
        style={{
          backgroundImage:
            "url('https://images.unsplash.com/photo-1701970047560-085f3fcff0ba?q=80&w=3731&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          height: "420px",
        }}
      >
        <div
          className="mask"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
        ></div>
      </div>
      <MDBContainer className="d-flex justify-content-center">
        <MDBAnimation
          animation="fade-in-up"
          duration={500}
          delay={100}
          start="onLoad"
          className="h-50 glass-card w-50 top-50 position-absolute mt-5 mb-4"
        >
          <MDBContainer className="container container bg-opacity-25 welcome_box bg-gradient shadow-5-strong h-100 p-4 w-100">
            <MDBAnimation
              animation="fade-in-up"
              duration={500}
              delay={300}
              start="onLoad"
            >
              <h3 className="text-light">
                Velkommen, {firstName} {lastName}
              </h3>
            </MDBAnimation>
            <hr className="hr w-50 hr-blurry" />
            <MDBAnimation
              animation="fade-in-up"
              duration={500}
              start="onLoad"
              delay={500}
            >
              <p className="text-white my-4">
                Valgt lokasjon: {selectedFaculty}
              </p>
            </MDBAnimation>
            <MDBAnimation
              animation="fade-in-up"
              start="onLoad"
              duration={500}
              delay={800}
            >
              <MDBBtn className="btn w-25" onClick={toggleOpen}>
                Changelog
              </MDBBtn>
            </MDBAnimation>
          </MDBContainer>
        </MDBAnimation>
      </MDBContainer>

      <MDBContainer>
        <MDBModal size="lg" open={basicModal} setopen={setBasicModal} staticBackdrop>
          <MDBModalDialog size="lg" scrollable>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>SISTE OPPDATERING</MDBModalTitle>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={toggleOpen}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <MDBBtn
                  className="reload-MDBBtn m-2"
                  color="secondary"
                  onClick={loadChangelogs}
                >
                  Last inn siste oppdatering
                </MDBBtn>
                <h3>Siste oppdateringer:</h3>
                <ul className="my-3 p-3">
                  {changelogs.map((changelog) => (
                    <li key={changelog.id} style={{ listStyle: "none" }}>
                      <h5>{changelog.title}</h5>
                      {changelog.changes && changelog.changes.length > 0 ? (
                        <ul>
                          {changelog.changes.split(",").map((change, index) => (
                            <li key={index}>{change.trim()}</li>
                          ))}
                        </ul>
                      ) : (
                        <p>Ingen endringer tilgjengelig.</p>
                      )}
                      <p>Dato: {changelog.formattedDate}</p>
                    </li>
                  ))}
                </ul>
              </MDBModalBody>

              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={toggleOpen}>
                  Lukk
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </MDBContainer>
    </MDBContainer>
  );
}

export default Hero