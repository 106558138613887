import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Modal from "../roaldvgs/adminModal/AdminModal";
import ModalStats from '../components/modalStats/ModalStats'; // Import your ModalStats component
import "./AllModals.css";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBCheckbox,
  MDBSelect,
  MDBIcon,
} from "mdb-react-ui-kit";

const AllModals = () => {
  const [modals, setModals] = useState([]);
  const selectedFaculty = localStorage.getItem("selectedFaculty");

  // Modal filter
  const [selectedFaculties, setSelectedFaculties] = useState([]);
  const [filteredModals, setFilteredModals] = useState([]);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [filters, setFilters] = useState({
    duplicates: false, // Ensure duplicates are not shown by default
    facultyName: [],
    selectedDevices: [], // Add filter for selected devices
  });

  const [tempFilters, setTempFilters] = useState(filters);

  const [selectedModalId, setSelectedModalId] = useState(null); // State to keep track of selected modal for statistics
  const [selectedFacultyName, setFacultyName] = useState(null); // State to keep track of selected modal for statistics
  const [showStatsModal, setShowStatsModal] = useState(false);

  const [modalName, setModalName] = useState(""); // Add this state for storing modalName

  const openStatsModal = (modalId, modalName, facultyName) => {
    console.log(
      "Opening stats modal for:",
      modalName,
      "from faculty:",
      facultyName
    );
    setSelectedModalId(modalId);
    setModalName(modalName);
    setFacultyName(facultyName); // Make sure this is properly set
    setShowStatsModal(true);
  };

  useEffect(() => {
    axios.get("/api/fetch_add_modal.php").then((response) => {
      console.log("Initial fetched modals:", response.data);
      const initialFilteredModals = applyFilters(response.data, filters); // Apply filters on initial fetch
      setModals(response.data);
      setFilteredModals(initialFilteredModals);
    });
  }, []);

  // Function to add a modal to the selected faculty with confirmation
  const addModalToFaculty = (modalId) => {
    // Display a confirmation dialog
    const isConfirmed = window.confirm(
      `Er du sikker på at du ønsker å legge til denne veiledningen i ${selectedFaculty}?`
    );

    if (isConfirmed) {
      // If the user confirms, send the POST request
      axios
        .post(
          "/api/fetch_add_modal.php",
          {
            modalId: modalId,
            selectedFaculty: selectedFaculty,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          // Handle success
          console.log(response.data);
          window.alert("Kopiering velykket");
        })
        .catch((error) => {
          // Handle errors
          console.error(error);
          window.alert("Kopiering feilet. Error:".error);
        });
    } else {
      // User canceled the action
      console.log("Handling avbrutt.");
    }
  };

  // Fetch filter data
  // Fetch all modals from the database
  const [faculties, setFaculties] = useState([]);

  useEffect(() => {
    axios
      .get("/api/fetch_add_modal.php")
      .then((response) => {
        if (Array.isArray(response.data)) {
          const uniqueFaculties = new Set(
            response.data.map((modal) => modal.facultyName)
          );
          setModals(response.data);
          setFilteredModals(applyFilters(response.data, filters));
          setFaculties([...uniqueFaculties]);
        } else {
          console.error(
            "Expected an array of modals, but received:",
            response.data
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching modals:", error);
      });
  }, []);

  // Calculate the modal count per faculty
  const modalCountPerFaculty = useMemo(() => {
    const count = {};
    modals.forEach((modal) => {
      const name = modal.facultyName;
      count[name] = (count[name] || 0) + 1;
    });
    return count;
  }, [modals]);

  const facultyOptions = useMemo(
    () =>
      faculties.map((facultyName) => ({
        text: facultyName,
        value: facultyName, // This must be correctly set
        secondaryText: `Antall: ${modalCountPerFaculty[facultyName] || 0}`,
      })),
    [faculties, modals]
  );

  // Handle filter changes
  const handleFilterChange = (filterName, value) => {
    setTempFilters((prev) => ({ ...prev, [filterName]: value }));
  };

  // Apply filters
  const applyFilters = (modalsToFilter, activeFilters) => {
    let updatedModals = [...modalsToFilter];
    if (activeFilters.facultyName.length > 0) {
      updatedModals = updatedModals.filter((modal) =>
        activeFilters.facultyName.includes(modal.facultyName)
      );
    }
    if (activeFilters.selectedDevices.length > 0) {
      updatedModals = updatedModals.filter((modal) =>
        activeFilters.selectedDevices.some((device) => modal[device] === "1")
      );
    }
    if (!activeFilters.duplicates) {
      updatedModals = filterDuplicates(updatedModals);
    }
    return updatedModals;
  };

  // Functions for the filters

  // Function to filter out duplicates
  const filterDuplicates = (modals) => {
    const seen = new Map();
    modals.forEach((modal) => {
      const key = modal.modalName + modal.createdBy;
      if (
        !seen.has(key) ||
        new Date(modal.created_at) < new Date(seen.get(key).created_at)
      ) {
        seen.set(key, modal);
      }
    });
    return Array.from(seen.values());
  };

  // Handle faculty selection changes
  const handleFacultySelectionChange = (selectedOptions) => {
    setTempFilters((prev) => ({
      ...prev,
      facultyName: selectedOptions.map((option) => option.value),
    }));
  };

  // Handle device selection changes
  const handleDeviceSelectionChange = (device) => {
    setTempFilters((prev) => {
      const selectedDevices = prev.selectedDevices.includes(device)
        ? prev.selectedDevices.filter((d) => d !== device)
        : [...prev.selectedDevices, device];
      return { ...prev, selectedDevices };
    });
  };

  // Update modals when filters change
  useEffect(() => {
    setFilteredModals(applyFilters(modals, filters));
  }, [filters, modals]);

  return (
    <MDBContainer fluid>
      <h2>Alle brukerveiledninger</h2>
      <div className="d-flex flex-column justify-content-center">
        <div className="d-flex flex-column ms-4 text-align">
          <MDBBtn
            className="mb-2"
            onClick={() => setFilterModalVisible(true)}
            color="secondary"
            tag="a"
            floating
          >
            <MDBIcon fas icon="magic" />
          </MDBBtn>
          <p>Filter</p>
        </div>
      </div>
      <div className="modals-list-wrapper">
        <MDBRow className="flex-wrap w-100">
          {filteredModals.map((modal) => (
            <MDBCol size="md" md="4" key={modal.id} className="d-inline-flex">
              <p className="ms-4 lead">hentet fra: {modal.facultyName}</p>
              <Modal
                modalData={modal}
                className="w-100 h-auto d-flex rounded"
              />
              <div className="hstack ms-4 gap-2">
                <MDBBtn
                  className="mb-3"
                  color="success"
                  onClick={() => addModalToFaculty(modal.id)}
                >
                  Kopier
                </MDBBtn>
                <MDBBtn
                  className="mb-3"
                  color="secondary"
                  onClick={() =>
                    openStatsModal(modal.id, modal.modalName, modal.facultyName)
                  }
                >
                  Statistikk
                </MDBBtn>
              </div>
            </MDBCol>
          ))}
        </MDBRow>
        {selectedModalId !== null && showStatsModal && (
          <ModalStats
            modalId={selectedModalId}
            isOpen={showStatsModal}
            onClose={() => setShowStatsModal(false)}
            modalName={modalName}
            facultyName={selectedFacultyName} // Ensure this prop is passed correctly
          />
        )}
      </div>
      <MDBModal
        open={filterModalVisible}
        setOpen={setFilterModalVisible}
        tabIndex="-1"
        staticBackdrop
      >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Endre filtere</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => setFilterModalVisible(false)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {/* Add filter inputs here, e.g, checkboxes for duplicates, facultyName dropdown, etc. */}
              {/* Checkbox for Duplicates */}
              <div className="mb-3">
                <MDBCheckbox
                  name="duplikater"
                  id="duplicateCheckbox"
                  label="Vis duplikater?"
                  className="mb-3"
                  onChange={(e) =>
                    handleFilterChange("duplicates", e.target.checked)
                  }
                  checked={tempFilters.duplicates}
                />
              </div>
              <div className="mb-3">
                <MDBSelect
                  name="Fakultet"
                  id="FakultetCheckbox"
                  label="Spesifikke fakulteter?"
                  search
                  multiple
                  data={facultyOptions}
                  getValue={handleFacultySelectionChange} // Handle selection changes
                ></MDBSelect>
              </div>
              <div className="mb-3">
                <p>Velg enheter</p>
                <MDBCheckbox
                  name="isModalMac"
                  id="macCheckbox"
                  label="Mac"
                  onChange={() => handleDeviceSelectionChange("modalMac")}
                  checked={tempFilters.selectedDevices.includes("modalMac")}
                />
                <MDBCheckbox
                  name="isModalWindows"
                  id="windowsCheckbox"
                  label="Windows"
                  onChange={() => handleDeviceSelectionChange("modalWindows")}
                  checked={tempFilters.selectedDevices.includes("modalWindows")}
                />
                <MDBCheckbox
                  name="isModalIphone"
                  id="iphoneCheckbox"
                  label="iPhone"
                  onChange={() => handleDeviceSelectionChange("modalIphone")}
                  checked={tempFilters.selectedDevices.includes("modalIphone")}
                />
                <MDBCheckbox
                  name="isModalWeb"
                  id="webCheckbox"
                  label="Web"
                  onChange={() => handleDeviceSelectionChange("modalWeb")}
                  checked={tempFilters.selectedDevices.includes("modalWeb")}
                />
                <MDBCheckbox
                  name="isModalAndroid"
                  id="androidCheckbox"
                  label="Android"
                  onChange={() => handleDeviceSelectionChange("modalAndroid")}
                  checked={tempFilters.selectedDevices.includes("modalAndroid")}
                />
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => setFilterModalVisible(false)}
              >
                Avbryt
              </MDBBtn>
              <MDBBtn onClick={() => {
                setFilters(tempFilters);
                setFilterModalVisible(false);
              }}>Sett filtere</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </MDBContainer>
  );
};

export default AllModals;
