import React, { useEffect, useState } from "react";
import {
  MDBAnimation,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBTypography,
  MDBSpinner,
} from "mdb-react-ui-kit";

const Stats = () => {
  const [stats, setStats] = useState({
    totalClicks: null,
    totalUsers: null,
    totalModals: null,
    isLoading: true,
  });
  const facultyName = localStorage.getItem("selectedFaculty");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `/api/fetchFacultyStats.php?facultyName=${facultyName}`
        );
        const data = await response.json();
        setStats({
          totalClicks: data.totalClicks,
          totalUsers: data.totalUsers,
          totalModals: data.totalModals,
          isLoading: false,
        });
      } catch (error) {
        console.error("Failed to fetch data", error);
        setStats((prev) => ({ ...prev, isLoading: false }));
      }
    };

    fetchData();
  }, [facultyName]);

  return (
    <MDBContainer className="d-inline w-100">
      <MDBAnimation
        duration="400"
        delay="300"
        start="onLoad"
        animation="fade-in"
        className="my-5 text-center"
      >
        <MDBTypography tag="h3" className="m-auto">
          Generell statistikk for - {facultyName}
        </MDBTypography>
        <hr className="hr hr-blurry" />
      </MDBAnimation>
      <MDBRow className="d-flex flex-row justify-content-center align-items-center w-100">
        {stats.isLoading ? (
          <MDBSpinner />
        ) : (
          <>
            <StatCard
              icon="crosshairs"
              title="Antall veiledninger åpnet"
              count={stats.totalClicks}
            />
            <StatCard
              icon="user"
              title={`Antall kollegaer`}
              count={stats.totalUsers}
            />
            <StatCard
              icon="file"
              title="Antall veiledninger"
              count={stats.totalModals}
            />
          </>
        )}
      </MDBRow>
    </MDBContainer>
  );
};

const StatCard = ({ icon, title, count }) => (
  <div className="col-md-3 m-3">
    <MDBAnimation
      animation="fade-in-up"
      delay="350"
      start="onLoad"
      duration="500"
      className="h-100 w-100"
    >
      <div className="glass-card m-auto p-4 h-100">
        <MDBTypography tag="h5">{title}</MDBTypography>
        <div className="stats d-flex flex-row h-100 w-100">
            <div className="col-sm-2 m-3 align-items-center justify-content-center d-flex">
              <MDBIcon fas icon={icon} size="3x" />
            </div>
            <div className="col-sm-2 m-4 align-items-center justify-content-center d-flex">
              <MDBTypography tag="h5" className="display-6 my-auto">
                {count || 0} {/* Fallback to 0 if data is undefined */}
              </MDBTypography>
            </div>
        </div>
      </div>
    </MDBAnimation>
  </div>
);

export default Stats;
