import React, { useState, useEffect } from "react";
import "./ClickCount.css";
import { MDBTable, MDBTableHead, MDBTableBody, MDBContainer, MDBAnimation } from 'mdb-react-ui-kit'
import ModalStats from './stats/Stats'

function ClickStats() {
  const [selectedFaculty, setSelectedFaculty] = useState(""); // State for selected faculty
  const [modalData, setModalData] = useState([]);

  useEffect(() => {
    // Get the selected faculty from local storage
    const storedFaculty = localStorage.getItem("selectedFaculty");
    setSelectedFaculty(storedFaculty);

    // Fetch modal click counts and rating statistics from API
    fetch("/api/getClickAndRatingStats.php")
      .then((response) => response.json())
      .then((data) => {
        // Filter the data based on the selected faculty
        const filteredData = data.filter(
          (item) => item.facultyName === storedFaculty
        );

        setModalData(filteredData);
      });
  }, []);

        const [theme, setTheme] = useState("light-mode");

        useEffect(() => {
          // Retrieve theme preference from localStorage
          const savedTheme = localStorage.getItem("theme");
          if (savedTheme) {
            setTheme(savedTheme);
            const container = document.body; // or your top-level container
            container.classList.add(savedTheme);
          }
        }, []);
    

  return (
    <MDBContainer className="position-relative d-inline w-100 justify-content-center align-items-center flex-column d-flex">
      <ModalStats />
      <MDBAnimation
        animation="fade-in-up"
        delay="750"
        start="onLoad"
        duration="500"
        className="my-3 w-100"
      >
        <MDBContainer className="my-4 glass-card p-4 d-inline-table">
          <div className="click_container">
            <MDBAnimation
              animation="fade-in"
              delay="800"
              start="onLoad"
              duration="500"
            >
              <h3 className="my-5">
                Detaljert statistikk for brukerveiledninger {selectedFaculty}
              </h3>
              <hr className="hr" />
            </MDBAnimation>
            {modalData.length === 0 ? (
              <p className="mt-4">Ingen statistikk tilgjengelig</p>
            ) : (
              <MDBAnimation
                animation="fade-in"
                delay="1100"
                start="onLoad"
                duration="500"
              >
                <MDBTable
                  responsive
                  borderless
                  rounded
                  className="bg-transparent"
                >
                  <MDBTableHead>
                    <tr>
                      <th scope="col">Brukerveiledning</th>
                      <th scope="col">Antall besøkende</th>
                      <th scope="col">God vurdering</th>
                      <th scope="col">Dårlig vurdering</th>
                      <th scope="col">Produsert</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {modalData.map((modal) => (
                      <tr key={modal.modalName}>
                        <td>{modal.modalName}</td>
                        <td>{modal.clickCount}</td>
                        <td>{modal.goodRatings || 0}</td>
                        <td>{modal.badRatings || 0}</td>
                        <td>{modal.created_at || 0}</td>
                      </tr>
                    ))}
                  </MDBTableBody>
                </MDBTable>
              </MDBAnimation>
            )}
          </div>
        </MDBContainer>
      </MDBAnimation>
    </MDBContainer>
  );
}

export default ClickStats;
